
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: var(--modal);
    padding: 20px;
    border-radius: 8px;
    min-width: 400px;
    max-width: 650px;
    text-align: center;
    max-height: 80%;
    overflow: scroll;
    z-index: 9999;
  }
  
  .close-btn {
    background-color: var(--error);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .custom-button-cancel{
    background-color: var(--error);
    color: white;
    border: none;
    border-radius: 8px;
    width: 90%;
    margin-top: 10px;
    padding: 10px;
    cursor: pointer;
    transition: 0.2s ease;
  }
  .custom-button-cancel:hover{
    background-color: #a03d39;
    transition: 0.2s ease;
    
  }
  
  .close-btn:hover {
    background-color: var(--highlight);
  }
  
  form input, form button {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--placeholder);
    border-radius: 5px;
  }
  
  form button {
    background-color: var(--success);
    color: #fff;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: var(--secondary);
  }
  