.create-store-container {
  max-width: 90%;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.create-store-title {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.create-store-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.create-store-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.create-store-column {
  flex: 1 1 48%; /* Ocupa metade do espaço disponível */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.create-store-column label {
  font-size: 1rem;
  color: #666;
}

.create-store-column input {
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.create-store-column input:focus {
  border-color: #007bff;
  outline: none;
}

.create-store-submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
  width: 50%;
  margin-top: 1.5rem;
}

.create-store-submit-button:hover {
  background-color: #0056b3;
}

.create-store-notification {
  padding: 15px;
  border-radius: 8px;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.create-store-notification-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.create-store-notification-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.cropper-container {
  margin-top: 20px;
  text-align: center;
}

.cropper-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.cropper-button:hover {
  background-color: #0056b3;
}

.preview-container {
  margin-top: 20px;
  text-align: center;
}

.logo-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.logo-input {
  display: none; /* Esconde o input de arquivo */
}

.logo-preview-container {
  width: 200px;
  height: 200px; /* Quadrado 4:4 */
  border: 2px dashed #007bff;
  border-radius: 12px; /* Cantos arredondados */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.logo-preview-container:hover {
  border-color: #0056b3;
  background-color: #f0f8ff;
}

.logo-placeholder {
  color: #007bff;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
}

.logo-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px; /* Mantém os cantos arredondados ao exibir a imagem */
}

/* Overlay do modal */
.cropper-modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Fundo opaco escuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}



/* Conteúdo do modal */
.cropper-modal-content {
  background: #ffffff; /* Fundo branco sólido */
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Sombra para destaque */
}

/* Título do modal */
.cropper-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

/* Botões no modal */
.cropper-modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.cropper-modal-buttons button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cropper-modal-buttons button:first-child {
  background-color: #f44336;
  color: white;
}

.cropper-modal-buttons button:last-child {
  background-color: #007bff;
  color: white;
}

.cropper-modal-buttons button:first-child:hover {
  background-color: #d32f2f;
}

.cropper-modal-buttons button:last-child:hover {
  background-color: #0056b3;
}
