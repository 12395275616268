:root {
  --primary: #4171DA;
  --secondary: #a6e22e;
  --tertiary: #8fd433;
  --highlight: #007bff;
  --modal: #ffffff;
  --background: #f9f9f9;
  --navbar: #f2f2f2;
  --placeholder: #c9c9c9;
  --alert: #f0ad4e;
  --error: #d9534f;
  --success: #5cb85c;
  --text: #000000;
  --card: #f2f2f2;
  --font-family: 'Roboto', sans-serif;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* Aplicando tipografia global */
body {
  font-family: var(--font-family);
  background-color: var(--background);
  color: var(--text);
  line-height: 1.6;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  display: flex;
  flex-direction: column;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estrutura básica das páginas */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

html, body{
  overscroll-behavior: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 15px;
  font-weight: bold;
  color: var(--primary);
}

p {
  margin-bottom: 15px;
  color: var(--text);
}

a {
  text-decoration: none;
  color: var(--highlight);
}

a:hover {
  color: var(--secondary);
}

/* Estilizando botões globais
button {
  display: inline-block;
  background-color: var(--highlight);
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

button:hover {
  background-color: var(--secondary);
} */


.flex-wrap {
  flex-wrap: wrap;
}

/* Responsividade */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .card {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
