/* Layout principal */
.dashboard-container {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f4f4f9;
  transition: all 0.3s ease;
}


/* Sidebar Expandida/Colapsada */
.sidebar {
  background-color: white;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  transition: width 0.3s ease;
  overflow: hidden;
}

.sidebar.expanded {
  width: 250px;
}

.sidebar.collapsed {
  width: 80px;
}

/* Toggle button para expandir/recolher a sidebar */
.toggle-btn {
  background: none;
  border: none;
  color: black;
  font-size: 1.2rem;
  cursor: pointer;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.sidebar-logo-container {
  text-align: center;
}

.sidebar-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.sidebar-menu ul {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  margin-bottom: 15px;
}

.menu-btn {
  background: none;
  border: none;
  color: black;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.1rem;
  padding: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.menu-btn:hover {
  background-color: #f2f2f2;
}

.menu-btn svg {
  margin-right: 10px;
}

/* Botão de Logout */
.logout-btn {
  background-color: #d9534f;
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #b52e2e;
}

/* Main Content */
.main-content {
  flex: 1;
  padding: 20px;
  transition: margin-left 0.3s ease;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 8px;
}

.header-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* Cards de Estatísticas */
.stats-cards {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.card {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  text-align: center;
}

.card h3 {
  font-size: 1.2rem;
}

.card p {
  font-size: 1rem;
}

/* Lista de Produtos */
.product-list {
  margin-top: 20px;
}

.products-container {
  display: flex;
  overflow-x: scroll;
  gap: 20px;
}

.product-card {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  min-width: 200px;
}

.product-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
}

.product-card h3 {
  color: #007bff;
  margin-top: 10px;
}

.product-card p {
  color: #1c1c1c;
}

/* Sidebar direita */
.sidebar-right {
  width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
}

.cashback-verification {
  background-color: rgba(0,0,0,0.045);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.business-rule-card {
  position: relative;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: #fff;
}

.card-actions {
  position: absolute;
  top: 10px;
  right: 10px;
}

.edit-button,
.remove-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  color: #888;
}

.edit-button:hover,
.remove-button:hover {
  color: #000;
}



/* Estilizar o contêiner dos ícones para exibir horizontalmente com rolagem */
.icon-selection-container {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
}

/* Estilo de cada ícone */
.icon-item {
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.icon-item:hover {
  transform: scale(1.1);
}

/* Estilo para o ícone selecionado */
.icon-item.selected {
  color: #ffa500;
  background-color: #fff5e0;
  border: 2px solid #ffa500;
}

/* Estilo para os inputs */
.custom-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.custom-input:focus {
  border-color: #ffa500;
  outline: none;
}

/* Estilo para o botão de confirmação */
.custom-button-confirm {
  width: 100%;
  padding: 12px;
  background-color: #ffa500;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button-confirm:hover {
  background-color: #ff8c00;
}


.custom-button-confirm {
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 8px;
  width: 90%;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}

.custom-button-confirm:hover {
  background-color: #428642;
}

.business-rules-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Espaçamento entre os cards */
}

.business-rule-card {
  background: #f9f9f9; /* Cor de fundo do card */
  border: 1px solid #ccc; /* Borda do card */
  border-radius: 8px; /* Bordas arredondadas */
  padding: 15px; /* Espaçamento interno */
  text-align: center; /* Centraliza o conteúdo */
  flex: 1 1 calc(33% - 20px); /* Três cards por linha com espaçamento */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra leve */
}


/* Responsividade */
@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }

  .sidebar,
  .sidebar-right {
    width: 100%;
  }

  .stats-cards {
    flex-direction: column;
  }

  .products-container {
    flex-direction: column;
  }
 
}
