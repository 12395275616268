/* Layout Principal */
.admin-dashboard-container {
    display: flex;
    background-color: #f5f5f5; /* Fundo claro */
    min-height: 100vh;
}

/* Sidebar */
.admin-sidebar {
    background-color: #24292e;
    width: 250px;
    padding: 20px;
    color: #ffffff;
}

.admin-sidebar-header {
    display: flex;
    flex-direction: column; /* Empilhando os botões verticalmente */
    gap: 15px; /* Espaçamento entre os botões */
}

.admin-sidebar-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.admin-sidebar-menu li {
    margin-bottom: 15px;
}

.admin-menu-btn,
.admin-toggle-btn {
    background: #3a3f44; /* Mesma cor dos outros botões */
    border: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    padding: 12px;
    cursor: pointer;
    border-radius: 8px; /* Bordas mais arredondadas */
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 100%;
}

.admin-menu-btn svg,
.admin-toggle-btn svg {
    margin-right: 10px;
}

.admin-menu-btn:hover,
.admin-toggle-btn:hover {
    background-color: #4a4f55; /* Alteração de cor ao passar o mouse */
    transform: translateX(5px); /* Animação sutil ao passar o mouse */
}

/* Main Content */
.admin-main-content {
    flex: 1;
    padding: 30px;
    background-color: #ffffff;
}

/* Header */
.admin-header {
    background-color: #ffffff;
    color: #444444;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* Cards */
.admin-stats-cards {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.admin-card {
    background-color: #FFF;
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    flex: 1;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.admin-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Usuários e Lojas */
.admin-users-container,
.admin-stores-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.admin-user-card,
.admin-store-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
    min-width: 220px;
}

.admin-user-card:hover,
.admin-store-card:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Botões */
.admin-user-actions,
.admin-store-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.admin-edit-button,
.admin-remove-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #888888;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}

.admin-edit-button:hover,
.admin-remove-button:hover {
    color: #444444;
}

/* Botão Personalizado para Modal */
.custom-button-confirm {
    background-color: #3a3f44;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.custom-button-confirm:hover {
    background-color: #4a4f55;
}


/* Estilo da Tabela */
.admin-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 12px;
    overflow: hidden; /* Para bordas arredondadas */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.admin-table th,
.admin-table td {
    padding: 15px 20px; /* Espaçamento interno */
    text-align: left;
}

.admin-table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0;
}

.admin-table thead th {
    font-weight: 600;
    font-size: 1rem;
    color: #333333;
}

.admin-table tbody tr {
    transition: background-color 0.3s ease;
}

.admin-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Alternância de cor para cada linha */
}

.admin-table tbody tr:hover {
    background-color: #f1f1f1; /* Cor de fundo ao passar o mouse */
}

.admin-table td {
    color: #555555;
    font-size: 0.95rem;
}

.admin-table tbody tr td:last-child {
    text-align: right; /* Alinhamento dos botões de ação */
}

.admin-table th {
    border-bottom: 1px solid #dddddd;
}

.admin-edit-button,
.admin-remove-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.1rem;
    transition: color 0.2s ease;
    padding: 5px 8px;
    margin: 0 5px;
    color: #888888;
}

.admin-edit-button:hover {
    color: #3498db; /* Cor azul para o botão de editar */
}

.admin-remove-button:hover {
    color: #e74c3c; /* Cor vermelha para o botão de remover */
}
/* Estilo para Textarea */
.admin-textarea {
    width: 100%;
    height: 100px;
    padding: 12px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    resize: vertical;
    background-color: #ffffff;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  

  
.custom-button-confirm {
    background-color: #4caf50; /* Verde para indicar confirmação */
    color: white;
    border: none;
    border-radius: 8px;
    width: 90%;
    margin-top: 10px;
    padding: 10px;
    cursor: pointer;
    transition: 0.2s ease;
  }
  
  .custom-button-confirm:hover {
    background-color: #45a049; /* Cor mais escura ao passar o mouse */
  }

  /* Layout de colunas para inputs */
.admin-modal-row {
    display: flex;
    gap: 15px; /* Espaçamento entre colunas */
    margin-bottom: 15px; /* Espaçamento inferior */
    width: 100%;
    min-width: 600px;
  }
  
  .admin-modal-column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .admin-modal-column label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .admin-modal-column input,
  .admin-modal-column textarea,
  .admin-modal-column select {
    width: 100%;
    padding: 12px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .admin-modal-column input:focus,
  .admin-modal-column textarea:focus,
  .admin-modal-column select:focus {
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
    outline: none;
  }
  
  