/* Layout geral do dashboard */
.custom-dashboard-container {
    padding: 20px;
    background-color: #f4f7fc;
    min-height: 100vh;
}
  
.custom-dashboard-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}
  
/* Gráficos de pizza */
.custom-dashboard-charts {
    display: flex;
    justify-content: left;
    margin-bottom: 30px;
    width: 100%;
    gap: 20px; /* Adiciona espaçamento entre os gráficos */
}

.custom-chart {
    width: 30%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    height: auto; /* Altura automática */
    min-height: 300px; /* Define um mínimo para a altura */
}

.custom-chart h2 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

/* Filtros de Estado e Cidade */
.custom-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-filters div {
    flex: 1;
    margin-right: 15px;
}

.custom-filters label {
    font-size: 14px;
    color: #333;
}

.custom-filters select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-top: 5px;
    background-color: #fff;
}
  
/* Tabela de Lojas */
.custom-store-table {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-store-table h2 {
    font-size: 18px;
    margin-bottom: 15px;
}

.custom-store-table table {
    width: 100%;
    border-collapse: collapse;
}

.custom-store-table th,
.custom-store-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 14px;
}

.custom-store-table th {
    background-color: #f9f9f9;
    font-weight: bold;
}
