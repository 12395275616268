.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  z-index: 1000;
  opacity: 0;
  transform: translateX(100%);
  animation: slideIn 0.5s forwards, slideOut 0.5s forwards 4.5s, shake 0.5s ease-in-out 0.5s; /* Adicionado o efeito de chacoalhada */
}

.notification.success {
  background-color: #28a745; /* Verde para sucesso */
}

.notification.error {
  background-color: #dc3545; /* Vermelho para erro */
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  margin-left: 20px;
  cursor: pointer;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  20%, 60% {
    transform: translateX(-10px);
  }
  40%, 80% {
    transform: translateX(10px);
  }
}
