.cpf-permitidos-container {
    padding: 20px;
  }
  
  .search-bar input {
    padding: 8px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .cpf-list {
    margin-bottom: 20px;
  }
  
  .cpf-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .add-cpf-section input {
    padding: 8px;
    margin-right: 10px;
  }
  
  .add-cpf-section button {
    padding: 8px 12px;
  }

  .cpf-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .cpf-table th,
  .cpf-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .cpf-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .remove-button {
    background-color: #e74c3c;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
  }
  
  .remove-button:hover {
    background-color: #c0392b;
  }
  
  .search-input {
    padding: 10px;
    width: 300px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .add-cpf-button {
    padding: 10px 15px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s;
  }
  
  .add-cpf-button:hover {
    background-color: #2980b9;
  }
  
  