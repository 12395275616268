/* Estilos comuns */
.custom-footer {
  padding: 20px 40px;
  font-family: 'Arial', sans-serif;
  position: relative;
  background-color: #24292e; /* Cor de fundo */
  color: #ffffff; /* Cor do texto */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Linha sutil */
}

.footer-logo img {
  max-width: 150px; /* Ajuste do tamanho da logo */
  height: auto;
}

.footer-contact {
  text-align: center;
}

.footer-contact h4 {
  font-size: 16px;
  margin-bottom: 8px;
  color: #ff9500; /* Cor destaque */
}

.footer-contact p {
  font-size: 14px;
}

.footer-contact a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
}

.footer-contact a:hover {
  color: #ff9500; /* Hover na cor de contato */
}

.footer-borderless-logo img {
  width: 150px; /* Ajuste o tamanho da logo da Borderless */
  position: relative;
}

/* Estilo da parte inferior */
.footer-bottom {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  font-size: 14px;
  color: #cccccc;
  align-items: center;
  gap: 10px;
}

.footer-bottom p {
  margin: 0;
}

.footer-bottom a {
  color: #ff9500; /* Cor do link */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer-bottom a:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* Type 1: Footer padrão */
.footer-type-1 {
  background-color: #24292e;
  color: #ffffff;
}
.footer-type-1 p{
  background-color: #24292e;
  color: #ffffffae;
}

.footer-type-1 h4 {
  color: #ff9500;
}

.footer-type-1 .footer-bottom {
  color: #cccccc;
}

/* Type 2: Footer com cores alternativas */
.footer-type-2 {
  background-color: var(--background);
  color: var(--text);
}

.footer-type-2 h4 {
  color: var(--secondary);
}

.footer-type-2 a {
  color: var(--highlight);
}

.footer-type-2 .footer-bottom {
  color: var(--placeholder);
}

/* Responsividade */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .footer-borderless-logo {
    margin-top: 20px;
  }
}
